

export const Addressee = (() =>
{

	function makemaker(pre, addr)
	{
		return pre + ":" + addr;
	}

	const Type = {
		user: "user",
		group: "group",
		meet: "meet",
		email: "email",
	};


	return {
		Type,
		MakeTo: (type, addr) => type + ":" + addr,
		Parse(a)
		{
			const m = a.match(/(\w+):(.+)/);
			return {
				type: m[1],
				addr: m[2]
			};
		},
		AlphaNum(a)
		{
			return a.replace(/[^a-zA-Z0-9]/gm, "");
		}
	}
})();

export function CallHash(a, b)
{
	return Math.max(a, b) + "_" + Math.min(a, b);
}

export function CallUrl(address)
{
	if (process.env.REACT_APP_P2PFOR_ONE_ON_ONE)
	{
		const a = Addressee.Parse(address);
		if (a.type === Addressee.Type.user)
			return "/p2p/" + address;
	}
	
	return "/call/" + address;
}

export function ChatUrl(address)
{
	return "/chat/" + address;
}

export function TeamCallUrl(a)
{
	return "/call/Team" + a;
}
