import { v4 as uuidv4 } from 'uuid';

import Token from "../Utils/Token";
import MAGICUSERS from "./Konst";

import "./index.scss";

import {store} from "../store";

let socket;

const uuidSession= uuidv4();


function magicinit(dispatch)
{
	const token = Token.get();
	if (token && !socket)
	{
		socket = window.io(process.env.REACT_APP_MAGICUSERS_ROOT, {
			query: { 
				token,
				uuidSession
			 },
			transports: ['websocket'],
//			forceNew:true,
//			multiplex:false,
		});

		let fConnected = false;

		socket.on("disconnect", ()=>{
			//socket.close();
		});

		socket.on("connect", () =>
		{
			console.debug("Connected to websocket server");

			if (!fConnected)
			{
				fConnected = true;

				socket.on("NewMood", mood =>
				{
					console.debug("NewMood", mood);
					dispatch({ type: MAGICUSERS.EVENT.NEWMOOD, payload: { mood } });
				});

				socket.on("NewGoal", goal =>
				{
					console.debug("NewGoal", goal);
					dispatch({ type: MAGICUSERS.EVENT.NEWGOAL, payload: { goal } });
				});

				socket.on("NewUserGoal", goal =>
				{
					console.debug("NewUserGoal", goal);
					dispatch({ type: MAGICUSERS.EVENT.NEWUSERGOAL, payload: { goal } });
				});

				socket.on("NewUserAvailability", available =>
				{
					console.debug("NewUserAvailability", available);
					dispatch({ type: MAGICUSERS.EVENT.NEWAVAILABILITY, payload: { available } });
				});

				socket.on("NewPersonalUser", (cmd, user) =>
				{
					console.debug("NewPersonalUser", cmd, user);
					dispatch({ type: MAGICUSERS.EVENT.NEWPERSONALUSER, payload: { cmd, user } });
				});

				socket.on("PersonalGroup", (cmd, user) =>
				{
					console.debug("PersonalGroup", cmd, user);
					dispatch({ type: MAGICUSERS.EVENT.PERSONALGROUP, payload: { cmd, user } });
				});


				socket.on("Group", (cmd, group) =>
				{
					console.debug("Group", cmd, group);
					dispatch({ type: MAGICUSERS.EVENT.GROUP, payload: { cmd, group } });
				});

				socket.on("GroupUser", (cmd, groupuser) =>
				{
					console.debug("GroupUser", cmd, groupuser);
					dispatch({ type: MAGICUSERS.EVENT.GROUPIE, payload: { cmd, groupuser } });
				});

				socket.on("GoalMatrix", (cmd, goal) =>
				{
					console.debug("GoalMatrix", goal);
					dispatch({ type: MAGICUSERS.EVENT.GOALMATRIX, payload: { cmd, goal } });
				});

				socket.on("UserManager", (cmd, user) =>
				{
					console.debug("UserManager", cmd, user);
					dispatch({ type: MAGICUSERS.EVENT.USERMANAGER, payload: { cmd, user } });
				});

				socket.on("Communicator", (cmd, ...args) =>
				{
					console.debug("Communicator", cmd, ...args);
					dispatch({ type: MAGICUSERS.EVENT.COMMUNICATOR, payload: { cmd, args } });
				});

				socket.on("Presence", (cmd, ...args) =>
				{
					console.debug("Presence", cmd, ...args);
					dispatch({ type: MAGICUSERS.EVENT.PRESENCE, payload: { cmd, args } });
				});

				socket.on("Article", (cmd, article) =>
				{
					console.debug("Article", cmd, article);
					dispatch({ type: MAGICUSERS.EVENT.ARTICLE, payload: { cmd, article } });
				});

			}


			dispatch({ type: MAGICUSERS.ACTION.CONNECTED, uuidSession });
		});
	}

}


export function ListenUsers(rg)
{
	console.debug("ListenUsers", socket,  rg);
	if (socket && rg)
		socket.emit("ListenUsers", rg);
}

export function ListenTeams(rg)
{
	console.debug("ListenTeams", socket, rg);
	if (socket&&rg)
		socket.emit("ListenGroups", rg);
}

export function Communicator(cmd, ...args)
{
	if (socket&&cmd)
		socket.emit("Communicator", cmd, ...args);
}

export function Reporting(name, args)
{
	if (socket&&name)
		socket.emit("Reporting", name, args);
}

export function CloseSocket()
{
	if (socket)
	{
		socket.close();
		socket=null;
	}
}

class MyUser extends window.Aθεος.Κίρκη.User
{
	constructor()
	{
		super(...arguments);
		console.debug("MyUser inserted");
	}
}

window.Aθεος.Κίρκη.User = MyUser;

class GameControl extends window.Aθεος.Αφροδίτη.SharedContainerWorld
{
	constructor()
	{
		console.debug("GameControl:constructor");
		super({
			Title: "Magic Overlord"
			, ReloadDocumentOnReset: true
			, Container: document.getElementById("idMagicUsersContainer")
			, InstanceID:Date.now()
			, Manifest:
			{
				Description: "Magic Web Apps Junction box."
			}
		});
	}

	OnInit()
	{
		console.debug("GameControl:OnInit");

		super.OnInit();
		//initgame.call(this);
	}
}

setTimeout(()=>{
	magicinit(store.dispatch);

}, 10);

let app;
window.Aθεος.Αφροδίτη.OnReady().then(() => {
	app = new GameControl();

} );

export function OnLogin(user)
{
	const {id, username} = user;

	console.debug("OnLogin", user, username, id);

	if (app && username && id)
	{
		app.NameChange(username, id);
	}
}


