import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';

import ACTIONS from "../actiontypes";
import { ListenUsers, ListenTeams, CloseSocket } from ".";
import MAGICUSERS from "./Konst";
import { OnLogin } from "./";

import { Addressee, CallUrl, ChatUrl } from "../Utils/Communicator";
import UserImage from "../components/User/Image";
import ProfileImage from '../components/Profile/Image';

import TeamImage from "../components/Team/Image";

function notifyMe(message, options, callback)
{
	function Notify()
	{
		var notification = new Notification(message, options);
		notification.onclick = ()=>window.focus();
	}

	// Let's check if the browser supports notifications
	if (!("Notification" in window))
	{
		alert("This browser does not support desktop notification");
	}

	// Let's check whether notification permissions have already been granted
	else if (Notification.permission === "granted")
	{
		// If it's okay let's create a notification
		Notify();
	}

	// At last, if the user has denied notifications, and you
	// want to be respectful there is no need to bother them any more.

}

function CallDeliver(store, senderid, cdr)
{
	const {
		userman: { userlist },
		common: { currentUser, activeCalls },
		teams,
	} = store.getState();
	console.debug("Calldeliver", userlist, senderid, cdr);
	const user = userlist.find(u => u.id == senderid);

	const onConfirm = () =>
	{

		const accept = () =>
		{
			console.debug("Call Accepted", user);
			store.dispatch({ type: ACTIONS.COMMUNICATOR.ANSWER, cdr, user })

		};
		store.dispatch({
			type: ACTIONS.NOTIFICATION.CREATE, notification:
			{
				title: `New Call`,
				description: (<div>
					<img
						src={user.image} alt={user.username}
					/>
					{user.username}
				</div>),
				actions: [
					["Join", accept]
				]
			}
		});
		
			notifyMe(`New Call`, {
				body: `${user.username}`,
				//requireInteraction: true
			},
				//accept
			);
		
		
		/*
		if (window.confirm(`Take a call from ${user.username} `))
			{
			console.debug("Call Accepted", user);
			store.dispatch({ type: ACTIONS.COMMUNICATOR.ANSWER, cdr, user })
				
			}
			*/
	}

	if (user && currentUser && user.id != currentUser.id)
	{
		const existingCall = activeCalls[cdr.address];
		const fAlreadyInCall = existingCall && existingCall.sessions.includes(currentUser.id);
		if (!fAlreadyInCall)
		{
			const a = Addressee.Parse(cdr.to);
			if (a.type === Addressee.Type.user)
			{
				if (a.addr == currentUser.id)
					onConfirm();
			}
			else if (a.type === Addressee.Type.group)
			{
				if (teams.memberList[a.addr].find(m => m.user === currentUser.id))
					onConfirm();
			}
		}
	}
}

function JoinCall(store, senderid, connection)
{
	const {
		userman: { userlist },
		common: { currentUser, uuidSession },
		connections,
		teams,
	} = store.getState();
	console.debug("JoinCall", userlist, senderid, connection);
	if (!(userlist && senderid && connection))
		return;

	const user = userlist.find(u => u.id == senderid);
	if (user)
	{
		store.dispatch({ type: ACTIONS.COMMUNICATOR.JOIN, user, connection })

		const fChat = connection.channel === "chat";

		const onConfirm = (title, description) =>
		{

			const accept = () =>
			{
				console.debug("Call Accepted", user);
				//store.dispatch({ type: ACTIONS.COMMUNICATOR.ANSWER, cdr, user })
				window.open(CallUrl(connection.address), "_blank");
			};

			store.dispatch({
				type: ACTIONS.NOTIFICATION.CREATE, notification:
				{
					title,
					description,
					actions:
						[(<Button
							href={fChat ? ChatUrl(connection.address) : CallUrl(connection.address)}
							target="_blank"
							rel="opener"
						>
							Join
						</Button>)]

				}
			});

			notifyMe(title, {
				body: user.username,
				icon: user.image,
				//requireInteraction: true
			},
				//accept
			);

		};

		if (currentUser && user.id != currentUser.id)
		{
			const address = connection.address;
			const myConnection = Object.values(connections).find(c => c.address === address && c.sessionid === uuidSession);
			if (!myConnection)
			{
				const a = Addressee.Parse(address);
				if (a.type === Addressee.Type.user)
				{
					if (a.addr.split("_").includes(currentUser.id.toString()))
						onConfirm(`${fChat ? "Chat" : "Call"}`, (
							<div>
								<img
									src={user.image} alt={user.username}
								/>
								{user.username}
							</div>
						));
				}
				else if (a.type === Addressee.Type.group)
				{
					const team = teams.details[a.addr];
					if (team && teams.memberList[a.addr].find(m => m.user === currentUser.id))
						onConfirm(`${fChat ? "Chat" : "Call"}: ${team.name}`, (
							<Row>
								<Col>
									<TeamImage
										image={team.image}
										name={team.name}
									/>
								</Col>
								<Col>
									<img
										src={user.image} alt={user.username}
									/>
									{user.username}
								</Col>
							</Row>

						));
				}
			}
		}
	}

}

function LeaveCall(store, senderid, connection)
{
	const {
		userman: { userlist },
		common: { currentUser },
		teams,
	} = store.getState();
	console.debug("LeaveCall", userlist, senderid, connection);
	const user = userlist.find(u => u.id == senderid);
	if (user)
	{
		store.dispatch({ type: ACTIONS.COMMUNICATOR.LEAVE, user, connection })
	}
}

function ConnectListen(store)
{

	const {
		userman: { userlist },
		common: { currentUser },
		teams,
	} = store.getState();

	if (userlist)
		ListenUsers(userlist.map(u=>u.id));

	if (teams && teams.details)
		ListenTeams(Object.keys(teams.details));

}

export default store => next => action =>
{
	switch (action.type)
	{
		case ACTIONS.APP.LOGIN:
		case ACTIONS.APP.LOAD:
			{
				if (action.payload)
				{
					const { user } = action.payload;
					if (user)
						OnLogin(user);
				}
			}

			break;

		case ACTIONS.APP.LOGOUT:
			CloseSocket();
			break;

		case MAGICUSERS.ACTION.CONNECTED:
			ConnectListen(store);
			break;

		case ACTIONS.USERMAN.LOADED:
			console.log("magicusers userlist", action);
			// if (!action.error)
			// 	ListenUsers(action.payload.users);
			break;

		case ACTIONS.TEAM.LOAD_DETAILS:
			if (!action.error)
				ListenTeams([action.payload.group.id]);
			break;

		case ACTIONS.TEAM.LOAD_SELF_USERS:
		case ACTIONS.TEAM.LOAD_PUBLIC_USERS:
		case ACTIONS.TEAM.LOAD_PUBLIC:
		case ACTIONS.TEAM.LOAD_LIST:
			if (!action.error)
				ListenTeams(action.payload.allGroups.map(g=>g.id));

			break;

		case MAGICUSERS.EVENT.GROUP:
			if (!action.error)
			{
				const { group, cmd } = action.payload;
				if (cmd === "create") 
				{
					ListenTeams([group.id]);
				}
				else if (cmd === "delete")
				{
				}

			}
			break;

		case MAGICUSERS.EVENT.COMMUNICATOR:
			switch (action.payload.cmd)
			{
				case "CallDeliver":
					CallDeliver(store, ...action.payload.args);
					break;

				case "JoinCall":
					JoinCall(store, ...action.payload.args);
					break;
				case "LeaveCall":
					LeaveCall(store, ...action.payload.args);
					break;

			}
			break;

		default:
			break;
	}

	next(action);
}
