import agent from "./agent";
import ACTIONS from "./actiontypes";

import Token from "./Utils/Token";
import jwt_decode from "jwt-decode";
import { push } from 'connected-react-router';

const promiseMiddleware = store => next => action =>
{
	if (isPromise(action.payload))
	{
		store.dispatch({ type: ACTIONS.ASYNC.START, subtype: action.type });

		action.payload.then(
			res =>
			{
				action.payload = res;
				store.dispatch(action);
			},
			error =>
			{
				console.debug(action, error);
				action.error = true;
				action.payload = error.response.body;
				store.dispatch(action);
			}
		)
		return;
	}
	next(action);
}

function isPromise(v)
{
	return v && typeof v.then === 'function';
}

const localStorageMiddleware = store => next => action =>
{

	function logout()
	{
		Token.clear();
		agent.setToken(null);
	}

	if (action.error && action.payload && action.payload.errors && action.payload.errors.message === "Unauthorized")
		store.dispatch(push("/logout"));
	else
		switch (action.type)
		{
			case ACTIONS.APP.REGISTER:
			case ACTIONS.APP.LOGIN:
			case ACTIONS.AUTH.REFRESH_TOKEN:
				if (action.error)
				{
					console.warn("Forcible logout", action.error);
					logout();
				}
				else
				{
					const token = action.payload.user.token;
					const decoded = jwt_decode(token);
					const clockdrift = Math.abs((decoded.iat * 1000) - Date.now());
					if (clockdrift > process.env.REACT_APP_ACCEPTABLE_DEVIATION_FROM_SERVER_CLOCK_SECONDS * 1000)
					{
						console.warn("clock mismatch unacceptable");
						window.alert("Client and Server clocks not in sync!");
						store.dispatch(push("/logout"));
					}
					else
					{
						Token.set(token);
						agent.setToken(token);
					}


				}
				break;


			case ACTIONS.APP.LOGOUT:
				logout();
				break;

			default:
				break;
		}

	next(action);
}

export
{
	localStorageMiddleware,
	promiseMiddleware
};