import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

import { store } from './store';
import jwt_decode from "jwt-decode";
import ACTIONS from "./actiontypes";
import { push } from 'connected-react-router';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = process.env.REACT_APP_API_ROOT;
const FILESTORE_ROOT = process.env.REACT_APP_MAGICUSERS_FILESTORE;

const responseBody = res => res.body;

let token = null;
let nextRefreshTime=null;
async function tokenPlugin(req)
{
	if (token)
	{
		req.set("authorization", `Token ${token}`);

		if (nextRefreshTime && Date.now() > nextRefreshTime)
		{
			nextRefreshTime=null;
			console.warn("Renewing Session...");
			const payload = await Auth.refresh();
			console.debug("refreshed", payload);
			store.dispatch({
				type: ACTIONS.AUTH.REFRESH_TOKEN,
				payload
			})

		}
	}
}


export function FileStorePath(file)
{
	return `${FILESTORE_ROOT}/${file}`;
}

export function FileStoreAttachmentPath(file)
{
	return `${FILESTORE_ROOT}/attachment/${file}`;
}

const requests = {
	get: url=>
		superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
	
	post:(url, body)=>
		superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),

	upload: (url, name, file, options) =>
		superagent.post(`${API_ROOT}${url}`).attach(name, file, options).use(tokenPlugin).then(responseBody),

	put: (url, body) =>
		superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
	
	del: url=>
		superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody)

}

const limit = (count, p) => `limit=${count}&offset=${p?p*count:0}`;
const omitSlug = article=>Object.assign({}, article, {slug:undefined});
const checkFeed = feed => feed?`feed=${feed}`:"";


const Articles = {
	all:(page, feed)=>
		requests.get(`/articles?${limit(10, page)}&${checkFeed(feed)}`),
	del:slug=>
		requests.del(`/articles/${slug}`),
	get: slug =>
		requests.get(`/articles/${slug}`),
	feed: () =>
		requests.get(`/articles/feed?limit=10&offset=0`),
	search: (page, query) =>
		requests.get(`/articles/search?${limit(10, page)}&q=${query}`),
	byAuthor: (author, page) =>
		requests.get(`/articles?author=${encodeURIComponent(author)}&${limit(5, page)}`),
	favorite: slug =>
		requests.post(`/articles/${slug}/favorite`),
	favoritedBy: (author, page) =>
		requests.get(`/articles?favorited=${encodeURIComponent(author)}&${limit(5, page)}`),
	unfavorite: slug =>
		requests.del(`/articles/${slug}/favorite`),
	update:article=>
		requests.put(`/articles/${article.slug}`, {article:omitSlug(article)}),
	create:article=>
		requests.post('/articles', {article})
}

const Auth={
	current:()=>
		requests.get("/user"),

	login:(email, password) =>
		requests.post("/users/login", {user:{email, password}}),

	register:(username, email, password)=>
		requests.post("/users", {user:{username, email, password}}),

	save:user=>
		requests.put("/user", {user}),

	refresh: () =>
		requests.get("/user/refresh"),

};


const Comments = {
	create: (slug, comment) =>
		requests.post(`/articles/${slug}/comments`, {comment}),
	delete: (slug, commentId) =>
		requests.del(`/articles/${slug}/comments/${commentId}`),
	forArticle: slug =>
		requests.get(`/articles/${slug}/comments`),
};

const Profile = {
	follow: (email) =>
		requests.post(`/profiles/${email}/follow`),
	get: (email) =>
		requests.get(`/profiles/${email}`),
	unfollow: email =>
		requests.del(`/profiles/${email}/follow`),
};

const UserManager = {
	list: () =>
		requests.get("/userman/list"),
	remove: (id) =>
		requests.del(`/userman/${id}`),
	get: (id) =>
		requests.get(`/userman/${id}`),
	save: user =>
		requests.put('/userman', { user }),
	create: user =>
		requests.post('/userman', { user }),
};

const Moods= {
	list: () =>
		requests.get("/moods/list"),
	set:(id) =>
		requests.put(`/mood_history/${id}`),
	get: (id) =>
		requests.get(`/mood_history/${id}`),
	status: (id) =>
		requests.get(`/moods/status`)

}

const Goals = {
	list: () =>
		requests.get("/goals/list"),
	set: (goal) =>
		requests.post(`/goal`, { goal}),
	get: (id) =>
		requests.get(`/goal/${id}`)
}

/*
const UserGoals = {
	set: (goalid, status) =>
		requests.put(`/usergoal/${goalid}/${status}`),
	get: (id) =>
		requests.get(`/usergoal/current/${id}`)
}
*/

const GoalMatrix = {
	set: (goalid, status) =>
		requests.put(`/goalmatrix/${goalid}/${status}`),
	list: (userid) =>
		requests.get(`/goalmatrix/index/${userid}`),
	status: (id) =>
		requests.get(`/goalmatrix/status/${id}`),
	allStatus: () =>
		requests.get(`/goalmatrix/allStatus`),
	remove: (id) =>
		requests.del(`/goalmatrix/${id}`),
	removeGoal: (id) =>
		requests.del(`/goalmatrix/goal/${id}`),
}


const Availability = {
	set: (status) =>
		requests.put(`/available/${status}`),
	get: (id) =>
		requests.get(`/available/current/${id}`),
	index: () =>
		requests.get(`/available/index`),
}

const PersonalDirectory = {
	add: (member) =>
		requests.put(`/pab/${member}`),
	del: (member) =>
		requests.del(`/pab/${member}`),
	list: () =>
		requests.get(`/pab/index`),
}

const PersonalTeamDirectory = {
	add: (member) =>
		requests.put(`/pab_group/${member}`),
	del: (member) =>
		requests.del(`/pab_group/${member}`),
	list: () =>
		requests.get(`/pab_group/index`),
}


const Team = {
	create: (group) =>
		requests.post(`/group`, { group }),
	update: (group) =>
		requests.put(`/group/${group.id}`, { group }),
	del: (id) =>
		requests.del(`/group/${id}`),
	details: (id) =>
		requests.get(`/group/${id}`),
	list: () =>
		requests.get(`/groupindex/self`),
	listPublic: () =>
		requests.get(`/groupindex/public`),

	add: (groupid, userid) =>
		requests.put(`/groupuser/${groupid}/${userid}`),
	remove: (id) =>
		requests.del(`/groupuser/${id}`),
	listTeams: () =>
		requests.get(`/groupuser/groups`),
	listUsers: (groupid) =>
		requests.get(`/groupuser/users/${groupid}`),
	listTeamsPublicUsers: () =>
		requests.get(`/groupuser/publicindex`),
	listTeamsPrivateUsers: () =>
		requests.get(`/groupuser/selfindex`),

}

const FileStore = {
	create: (name, file, options) =>
		requests.upload(`/filestore`, name, file, options),
	get: (id) =>
		requests.get(`/filestore/${id}`),
	del: (id) =>
		requests.del(`/filestore/${id}`),
}

const Meeting = {
	create: (meeting) =>
		requests.post(`/meeting`, { meeting }),

}

const Call = {
	list: () =>
		requests.get(`/call/index`),
	listPublic: () =>
		requests.get(`/call/public`),

	add: (callid, userid) =>
		requests.put(`/connection/${callid}/${userid}`),
	remove: (id) =>
		requests.del(`/connection/${id}`),
}
const Connection = {
	list: () =>
		requests.get(`/connections/index`),
}


const OpenVidu={
	createToken: (sessionId)=>
		requests.put(`/openvidu/token/${sessionId}`),
}

let idExpiry;
function setToken(t)
{
	token=t;
	clearTimeout(idExpiry);

	if(token)
	{
		const decoded = jwt_decode(token);
		const expirytime = Math.max(0, (decoded.exp * 1000) - Date.now());
		const nextRefreshTime = Math.max(0, expirytime - process.env.REACT_APP_IDLE_TIME_BEFORE_FORCED_LOGOUT_SECONDS*1000);
		console.debug("jwt", expirytime, nextRefreshTime, decoded);

		idExpiry = setTimeout(() =>
		{
			console.warn("expired...");
			store.dispatch(push("/logout"));
		}, expirytime);
	}




}

export default{
	Articles,
	Auth,
	Comments,
	Profile,
	UserManager,
	Moods,
	Goals,
//	UserGoals,
	Availability,
	PersonalDirectory,
	PersonalTeamDirectory,
	Team,
	GoalMatrix,
	FileStore,
	Meeting,
	Call,
	Connection,
	OpenVidu,
	setToken
};