import React from 'react';
import { Alert, Button } from 'react-bootstrap';

export default function (props)
{
	if (!('Notification' in window))
	{
		console.log("This browser does not support notifications.");
		return null;
	}

	const [showDialog, setDialog] = React.useState(false);

	React.useEffect(() =>
	{
		setDialog(Notification.permission !== 'granted');
	}, []);


	return (
		<Alert
			{...props}
			variant="info"
			show={showDialog}
		>
			<Alert.Heading>
				Receive notifications outside browser window?
			</Alert.Heading>
			<hr />
			<div className="d-flex justify-content-end">
				<Button onClick={() =>
				{
					setDialog(false);
				}}
					variant="outline-light">
					Not Now
				</Button>

				<Button onClick={() =>
				{
					Notification.requestPermission()
						.then((permission) =>
						{
							console.debug("Permission request repsonse", permission);
							if (permission !== "granted")
								alert("Permission denied by browser");

							setDialog(permission !== 'granted');
						})

				}}
					variant="outline-success">
					Yes
         		</Button>
			</div>

		</Alert>

	)
}

