import React from 'react';
import agent, { FileStorePath } from '../agent';



export async function Upload(jsfile)
{
	console.debug("Save file", jsfile);

	if (jsfile)
	{
		const result = await agent.FileStore.create("myfile", jsfile);
		console.debug(result);
		return result;		
	}

}

export async function Save(jsfile)
{
	const result = await Upload(jsfile);
	return FileStorePath(result.entry.path);
}

/* Utility function to convert a canvas to a BLOB */
var dataURLToBlob = function (dataURL)
{
	var BASE64_MARKER = ';base64,';
	if (dataURL.indexOf(BASE64_MARKER) == -1)
	{
		var parts = dataURL.split(',');
		var contentType = parts[0].split(':')[1];
		var raw = parts[1];

		return new Blob([raw], { type: contentType });
	}

	var parts = dataURL.split(BASE64_MARKER);
	var contentType = parts[0].split(':')[1];
	var raw = window.atob(parts[1]);
	var rawLength = raw.length;

	var uInt8Array = new Uint8Array(rawLength);

	for (var i = 0; i < rawLength; ++i)
	{
		uInt8Array[i] = raw.charCodeAt(i);
	}

	return new Blob([uInt8Array], { type: contentType });
}
/* End Utility function to convert a canvas to a BLOB      */

export function ReduceImagesize(file, max_size)
{
	return new Promise((resolve, reject) =>
	{
		try
		{
			if (!max_size)
				resolve(file);
			else if (!file.type.match(/image.*/))
				throw "Bad image";
			else
			{
				// Load the image
				var reader = new FileReader();
				reader.onload = function (readerEvent)
				{
					var image = new Image();
					image.onload = function (imageEvent)
					{
						let width = image.width;
						let height = image.height;
						
						console.log(`An image has been loaded ${width}x${height}`, max_size);

						if (width <= max_size && height <= max_size)
							resolve(file);
						else
						{
							// Resize the image
							var canvas = document.createElement('canvas');
							if (width > height)
							{
								if (width > max_size)
								{
									height *= max_size / width;
									width = max_size;
								}
							} else
							{
								if (height > max_size)
								{
									width *= max_size / height;
									height = max_size;
								}
							}
							canvas.width = width;
							canvas.height = height;
							canvas.getContext('2d').drawImage(image, 0, 0, width, height);
							var dataUrl = canvas.toDataURL('image/jpeg');
							var resizedImage = dataURLToBlob(dataUrl);

							resolve(new File([resizedImage], file.name));
						}

					}
					image.src = readerEvent.target.result;
				}
				reader.readAsDataURL(file);
			}
		}
		catch (e)
		{
			reject(e);
		}

	});

}