import agent, { FileStoreAttachmentPath } from "../../agent";
import React from "react";
import Button from 'react-bootstrap/Button';

export default function (props)
{
	const { fileinfo, showsize, procClose } = props;
	if (fileinfo && fileinfo.path)
	{
		const path = FileStoreAttachmentPath(fileinfo.path);
		const mimetype = fileinfo.mimetype || fileinfo.type;

		return (<React.Fragment>
			{
				procClose && <Button 
					className="close" 
					aria-label="Close"
					onClick={procClose}
					>
					<span aria-hidden="true">&times;</span>
				</Button>

			}
			{
				mimetype.startsWith("image/") ? (
					<img
						src={path}
					>
					</img>
				) : (<div>
					<a
						href={path}
						download={fileinfo.name}
					>📎 {fileinfo.name}
						{showsize && (<small>&nbsp;({fileinfo.size} B)</small>)}
					</a>
				</div>)
			}
		</React.Fragment>
		);
	}
	return null;
}