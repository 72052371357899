import React from 'react';
import { Link } from "react-router-dom";

import agent from '../agent';
import { connect } from 'react-redux';
import ACTIONS from "../actiontypes";

import Attachment from "./Article/Attachment";

const mapDispatchToProps = dispatch => ({
	favorite: slug => dispatch({
		type: ACTIONS.ARTICLE.FAVORITED,
		payload: agent.Articles.favorite(slug)
	}),
	unfavorite: slug => dispatch({
		type: ACTIONS.ARTICLE.UNFAVORITED,
		payload: agent.Articles.unfavorite(slug)
	})
});

const FAVORITED_CLASS = 'btn btn-sm btn-primary';
const NOT_FAVORITED_CLASS = 'btn btn-sm btn-outline-primary';


const ArticlePreview = props =>
{
	const article = props.article;
	const favoriteButtonClass = article.favorited ?
		FAVORITED_CLASS :
		NOT_FAVORITED_CLASS;


	const handleClick = ev =>
	{
		ev.preventDefault();
		if (article.favorited)
		{
			props.unfavorite(article.slug);
		} else
		{
			props.favorite(article.slug);
		}
	};

	return (
		<div className="article-preview">
			<div className="article-meta">
				<Link to={`/@${article.author.email}`}>
					<img src={article.author.image} alt={article.author.username} />
				</Link>

				<div className="info">
					<Link className="author" to={`/@${article.author.email}`}>
						{article.author.username}
					</Link>
					<span className="date">
						{new Date(article.createdAt).toDateString()}
					</span>
				</div>
				<div className="float-sm-right">
					<button
						className={favoriteButtonClass}
						onClick={handleClick}
						>
						<i className="ion-heart"></i>{article.favoritesCount}
					</button>
				</div>
			</div>

			<Link to={`/article/${article.slug}`} className="preview-link">
				<h1>{article.title}</h1>
				<div className="article-attachment">
					<Attachment
						fileinfo={article.attachment}
					/>
				</div>
				<p>{article.body.substr(0, 128)}</p>
				<span>Read more...</span>
			</Link>
		</div>
	);
}

export default connect(() => ({}), mapDispatchToProps)(ArticlePreview);
