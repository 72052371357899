import React from "react";
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

import agent from "../../agent";

import MainHeader from "../Header";

import MainView from "./MainView";
import UserDesk from "../Desk";
import Carousel from "../Carousel";
import Meeting from "../Meeting";
import Settings from "../Settings";
import Goal from "../Goal";

import NotificationPermission from "../Notification/Permission";
import Notifications from "../Notification";

import ACTIONS from "../../actiontypes";
import FEED from "../../feedtypes";


const mapStateToProps = state => ({
	...state.home,
	appName: state.common.appName,
	token: state.common.token
});

const mapDispatchToProps = dispatch => ({
	onLoad: (tab, payload) =>
		dispatch({ type: ACTIONS.PAGE.HOME_LOADED, tab, payload }),
	onUnload: () =>
		dispatch({ type: ACTIONS.PAGE.HOME_UNLOADED })
});

function Home(props)
{
	const [showDialog, setDialog] = React.useState(null);

	const DIALOG_NAME = {
		Meeting: "meeting",
		Team: "team",
		UserSettings: "settings",
		Goal: "goal",
	};

	React.useEffect(() =>
	{
		props.onLoad(FEED.GLOBAL, agent.Articles.all(0, FEED.GLOBAL));

		return () => { props.onUnload(); };
	}, []);


	return (
		<div className="h-100">
			<MainHeader
				onCreateMeeting={setDialog.bind(this, DIALOG_NAME.Meeting)}
				onCreateTeam={setDialog.bind(this, DIALOG_NAME.Team)}
				onUserSettings={setDialog.bind(this, DIALOG_NAME.UserSettings)}
				onCreateGoal={setDialog.bind(this, DIALOG_NAME.Goal)}
			/>
			<div className="d-flex flex-column h-100">
 				<NotificationPermission
				/>

				<div className="flex-fill position-relative">
					<Container fluid className="page position-absolute " style={{ top: 0, bottom: 0, left: 0 }}>
						<Row className="h-100">
							<Col className="col-8 h-100">
								<div className="dskly-carousel-container">
									<Carousel />
								</div>
								<div className="dskly-userdesk-container">
									<UserDesk />
								</div>
							</Col>
							<Col className="col-4 px-0 h-100">
								<MainView />
							</Col>
						</Row>
					</Container>
				</div>

			</div>


			<Meeting
				show={showDialog === DIALOG_NAME.Meeting}
				onHide={setDialog.bind(this, null)}
				onSubmitOveride={setDialog.bind(this, null)}

			/>
			{/* 

			<TeamEditor
				show={showDialog === DIALOG_NAME.Team}
				onHide={ setDialog.bind(this, null)}
				
			/>
			<Settings
				show={showDialog === DIALOG_NAME.UserSettings}
				onHide={setDialog.bind(this, null)}
			/>
			<Goal
				show={showDialog === DIALOG_NAME.Goal}
				onHide={setDialog.bind(this, null)}
			/> */}

		</div>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);